import React from "react";
import checkIcon from "assets/images/check.svg";
import closeBlueIcon from "assets/images/close-blue.svg";
import { useuserFilterTableStore } from "store/UserManagementStore";
import { shallow } from "zustand/shallow";
import moment from "moment";
import { formatFilterDate } from "helpers";

const RenderFilters = (filter, toggleFilter, setRefresh, setUserListFilterInput, userListFilterInput) => {
    const { removeQuickFilterFunc, removeRoleFunc, removeStatusFunc } = useuserFilterTableStore(
        (state) => ({
            removeQuickFilterFunc: state.removeQuickFilter,
            removeRoleFunc: state.removeRole,
            removeStatusFunc: state.removeStatus,
        }),
        shallow
    );

    switch (filter.type) {
        case "name":
            if (filter.name && !toggleFilter)
                {return (
                    <div className="quick-filter-box close">
                        <label className="text-capatalize">{filter.type}:</label>
                        <p>{filter.name}</p>
                        <img
                            src={closeBlueIcon}
                            alt="Close"
                            onClick={() => {
                                removeQuickFilterFunc("name");
                                setRefresh((prev) => prev + 1);
                                setUserListFilterInput({
                                    name: "",
                                });
                            }}
                        />
                    </div>
                );}
            break;

        case "createdStartDate":
            if (filter?.createdStartDate && !toggleFilter)
                {return (
                    <div className="quick-filter-box close">
                        <label>Created Start:</label>
                        <p>{formatFilterDate(filter?.createdStartDate?.toString())}</p>
                        <img
                            src={closeBlueIcon}
                            alt="Close"
                            onClick={() => {
                                setUserListFilterInput({
                                    createdStartDate: "",
                                });
                                removeQuickFilterFunc("createdStartDate");
                                setRefresh((prev) => prev + 1);
                            }}
                        />
                    </div>
                );}
            break;
        case "createdEndDate":
            if (filter.createdEndDate && !toggleFilter)
                {return (
                    <div className="quick-filter-box close">
                        <label>Created End:</label>
                        <p>{formatFilterDate(filter?.createdEndDate?.toString())}</p>
                        <img
                            src={closeBlueIcon}
                            alt="Close"
                            onClick={() => {
                                setUserListFilterInput({
                                    createdEndDate: "",
                                });
                                removeQuickFilterFunc("createdEndDate");
                                setRefresh((prev) => prev + 1);
                            }}
                        />
                    </div>
                );}
            break;

        case "roleFilter":
            if (filter.roleFilter.length && !toggleFilter)
                {return filter.roleFilter.map((role) => (
                    <div className="quick-filter-box close">
                        <label>Role:</label>
                        <p>{role?.roleName}</p>
                        <img
                            src={closeBlueIcon}
                            alt="Close"
                            onClick={() => {
                                const filteredRole = userListFilterInput?.role?.filter((r) => r.aclRoleId !== role.aclRoleId);
                                setUserListFilterInput({
                                    role: filteredRole,
                                });
                                removeRoleFunc("roleFilter", role.aclRoleId);
                                setRefresh((prev) => prev + 1);
                            }}
                        />
                    </div>
                ));}
            break;

        case "statusFilter":
            if (filter.statusFilter.status && !toggleFilter)
                {return (
                    <div className="quick-filter-box close">
                        <label>Status:</label>
                        <p>{filter.statusFilter.status}</p>
                        <img
                            src={closeBlueIcon}
                            alt="Close"
                            onClick={() => {
                                setUserListFilterInput({
                                    status: {},
                                });
                                removeStatusFunc("statusFilter", filter.statusFilter.id);
                                setRefresh((prev) => prev + 1);
                            }}
                        />
                    </div>
                );}

            break;

        default:
            break;
    }
};

const QuickRecentFilters = ({ headerQuickSelectedFilters, toggleFilter, setRefresh, setUserListFilterInput, userListFilterInput }) => {
    return (
            <span className="quick-filter-sec ms-2">{headerQuickSelectedFilters?.map((filter) => RenderFilters(filter, toggleFilter, setRefresh, setUserListFilterInput, userListFilterInput))}</span>
    );
};

export default QuickRecentFilters;
