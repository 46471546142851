import React from "react";
import checkIcon from "assets/images/check.svg";
import closeBlueIcon from "assets/images/close-blue.svg";
import { useuserFilterTableStore } from "store/UserManagementStore";
import { shallow } from "zustand/shallow";
import moment from "moment";

const RenderFilters = (filter, toggleFilter) => {
    const { removeQuickFilterFunc, removeRoleFunc, removeStatusFunc } = useuserFilterTableStore(
        (state) => ({
            removeQuickFilterFunc: state.removeQuickFilter,
            removeRoleFunc: state.removeRole,
            removeStatusFunc: state.removeStatus,
        }),
        shallow
    );

    switch (filter.type) {
        case "name":
            if (filter.name && !toggleFilter)
                {return (
                    <div className="quick-filter-box close">
                        <label>{filter.type}:</label>
                        <p>{filter.name}</p>
                        <img src={closeBlueIcon} alt="Close" onClick={() => removeQuickFilterFunc("name")} />
                    </div>
                );}
            break;

        case "createdStartDate":
            if (filter.createdStartDate && !toggleFilter)
                {return (
                    <div className="quick-filter-box close">
                        <label>Created Start:</label>
                        <p>{filter.createdStartDate.toString()}</p>
                        <img src={closeBlueIcon} alt="Close" onClick={() => removeQuickFilterFunc("createdStartDate")} />
                    </div>
                );}
            break;
        case "createdEndDate":
            if (filter.createdEndDate && !toggleFilter)
                {return (
                    <div className="quick-filter-box close">
                        <label>Created End:</label>
                        <p>{filter.createdEndDate.toString()}</p>
                        <img src={closeBlueIcon} alt="Close" onClick={() => removeQuickFilterFunc("createdEndDate")} />
                    </div>
                );}
            break;

        case "roleFilter":
            if (filter.roleFilter.length && !toggleFilter)
                {return filter.roleFilter.map((role) => (
                    <div className="quick-filter-box close">
                        <label>Role:</label>
                        <p>{role?.roleName}</p>
                        <img src={closeBlueIcon} alt="Close" onClick={() => removeRoleFunc("roleFilter", role.aclRoleId)} />
                    </div>
                ));}
            break;

        case "statusFilter":
            if (filter.statusFilter.status && !toggleFilter)
                {return (
                    <div className="quick-filter-box close">
                        <label>Status:</label>
                        <p>{filter.statusFilter.status}</p>
                        <img src={closeBlueIcon} alt="Close" onClick={() => removeStatusFunc("statusFilter", filter.statusFilter.id)} />
                    </div>
                );}

            break;

        default:
            break;
    }
};

const QuickRecentFiltersOperator = ({ headerQuickSelectedFilters, toggleFilter }) => {
    return (
        <div className="overflow-auto me-3">
            <div className="quick-filter-sec">{headerQuickSelectedFilters?.map((filter) => RenderFilters(filter, toggleFilter))}</div>
        </div>
    );
};

export default QuickRecentFiltersOperator;
