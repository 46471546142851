import { Loader as KLoader } from "@progress/kendo-react-indicators";

export default function Loader() {
  return (
    <div className="bg-preloader">
      <div className="loader-container" data-testid="loader-container" >
        <KLoader type="infinite-spinner" size="large" />
      </div>
    </div>
  );
}
