import React, { useEffect, useMemo, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { shallow } from "zustand/shallow";
import useAxiosPost from "hooks/useAxiosPost";
import { Urls } from "helpers/api-urls";
import { Button } from "@progress/kendo-react-buttons";
import { useClientProfileStore } from "store/ClientProfileStore";
import iconViewTrip from "assets/images/icon-view-trip.svg";
import startShedulerBlue from "assets/images/start-sheduler-blue.svg";
import {
    afterAcceptedStatusShow,
    calculatePercentage,
    checkBrokerType,
    convertMinutesInHourAndMinutes,
    convertTo24HourFormat,
    formatBrokerTypes,
    formatCurrency,
    navigateMaverick,
    rednderClientStatusIcon,
    rednderTripStatusIcon,
} from "helpers";
import LoaderInner from "components/common/LoaderInner";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import Pie from "components/common/CirclePie";
import { useTripProfileStore } from "store/TripProfileStore";
import { useNavigate } from "react-router-dom";
import priorityGreenBar from "assets/images/priority-green-bar.svg";
import priorityOrangeBar from "assets/images/priority-oranage-bar.svg";
import priorityRedBar from "assets/images/priority-red-bar.svg";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { CustomSingleSelect } from "components/common/CustomSingleSelect";
import { useMasterApiStore } from "store/MasterDataStore";
import CustomTooltip from "components/common/CustomTooltip";
import restart from "assets/images/restart.svg";


const ClientProfile = () => {
    const { openClientProfile, setOpenClientProfileView, clientId, openData } = useClientProfileStore(
        (state) => ({
            openClientProfile: state.openClientProfile,
            setOpenClientProfileView: state.setOpenClientProfileView,
            clientId: state.clientId,
            openData: state.openData,
        }),
        shallow
    );
    const { setOpenTripProfileView, setOpenTripData, setTripId, setLegId, setDataSourceIdForTrip, setIsRefreshLegListFromTripView, isRefreshLegListFromTripView } = useTripProfileStore(
        (state) => ({
            setOpenTripProfileView: state.setOpenTripProfileView,
            setLegId: state.setLegId,
            setTripId: state.setTripId,
            setDataSourceIdForTrip: state.setDataSourceIdForTrip,
            setIsRefreshLegListFromTripView: state.setIsRefreshLegListFromTripView,
            isRefreshLegListFromTripView: state.isRefreshLegListFromTripView,
            setOpenTripData: state.setOpenTripData,
        }),
        shallow
    );

    const { getAllPriorityMasterData } = useMasterApiStore(
        (state) => ({
            getAllPriorityMasterData: state.getAllPriorityMasterData,
        }),
        shallow
    );
    const [refresh, setRefresh] = useState(0);
    useEffect(() => {
        if (openClientProfile) {
            document.body.classList?.add("hide-scroll-on-modal-open");
            return () => {
                document.body.classList?.remove("hide-scroll-on-modal-open");
            };
        }
    }, [openClientProfile]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const { loadingPostData, sendRequest, data } = useAxiosPost();
    const { loadingPostData: loadingbrokerInfoPostData, sendRequest: sendRequestToGetBrokerInfo, data: brokerData } = useAxiosPost();
    const { loadingPostData: loadingClientInfoFromPhoenix, sendRequest: sendRequestToGetClientInfoFromPhoenix, data: clientInfoFromPhoenix } = useAxiosPost();
    const { loadingPostData: loadingTripInfoLastTripDetails, sendRequest: sendRequestToGetLastTripDetails, data: lastTripDetails } = useAxiosPost();
    const { loadingPostData: loadingTripInfoUpcomingTripDetails, sendRequest: sendRequestToGetUpcomingTripDetails, data: upcomingTripDetails } = useAxiosPost();
    const {
        loadingPostData: loadingClientInfoForMarginAndPriorityDetailsLastTrip,
        sendRequest: sendRequestToGetClientInfoForMarginAndPriorityLastTrip,
        data: clientInfoForMarginAndPriorityLastTripData,
    } = useAxiosPost();
    const {
        loadingPostData: loadingClientInfoForMarginAndPriorityDetailsUpcomingTrip,
        sendRequest: sendRequestToGetClientInfoForMarginAndPriorityUpcomingTrip,
        data: clientInfoForMarginAndPriorityUpcomingTripData,
    } = useAxiosPost();

    useEffect(() => {
        (async () => {
            const requests = [
                sendRequest("get", `${Urls.GetClientProfile}/${clientId}`, {}, "MV"),
                sendRequestToGetBrokerInfo("get", `${Urls.GetClientProfileBrokerInfo}/${clientId}`, {}, "MV"),
                sendRequestToGetClientInfoFromPhoenix("post", `${Urls.GetTripDetailForClientPhoenix}`, {
                    clientId,
                }),
            ];
            await Promise.all(requests).then(() => setLoading(false));
        })();
    }, [clientId, refresh]);
    const [assignPriority, setAssignpriority] = useState([]);
    const { loadingPostData: assignPriorityLoading, sendRequest: sendRequestToAssignPriority, data: assignPriorityApiResponse } = useAxiosPost();

    const handleLegListAssignPriority = async (name, value, legId) => {
        const payload = {
            legId: legId,
            schedulerPriority: {
                ...value,
            },
        };
        setLoading(true);
        setAssignpriority({ [name]: value });
        await sendRequestToAssignPriority("post", `${Urls.AssignSchedulerPriority}`, {
            ...payload,
        });
        setRefresh((prev) => prev + 1);
        setIsRefreshLegListFromTripView(isRefreshLegListFromTripView);
    };
    const clientInfoData = clientInfoFromPhoenix?.responseData;
    useEffect(() => {
        (async () => {
            const requests = [];
            if (clientInfoData?.lastAndUpcomingTripDetails?.length) {
                const lastTripId = clientInfoFromPhoenix.responseData.lastAndUpcomingTripDetails?.find((trip) => trip?.tripTime === "last_trip");
                if (lastTripId?.id && lastTripId?.dataSourceId) {
                    requests.push(sendRequestToGetLastTripDetails("get", `${Urls.GetTripDetails(lastTripId.dataSourceId)}`, {}, "MV"));
                    requests.push(sendRequestToGetClientInfoForMarginAndPriorityLastTrip("get", `${Urls.GetClientMarginAndPriorityDetails(lastTripId.id)}`));
                }
                const upcomingTripId = clientInfoFromPhoenix.responseData.lastAndUpcomingTripDetails?.find((trip) => trip?.tripTime === "upcoming_trip");

                if (upcomingTripId?.id && upcomingTripId?.dataSourceId) {
                    requests.push(sendRequestToGetUpcomingTripDetails("get", `${Urls.GetTripDetails(upcomingTripId.dataSourceId)}`, {}, "MV"));
                    requests.push(sendRequestToGetClientInfoForMarginAndPriorityUpcomingTrip("get", `${Urls.GetClientMarginAndPriorityDetails(upcomingTripId.id)}`));
                }
                await Promise.all(requests);
            }
        })();
    }, [clientInfoData?.lastAndUpcomingTripDetails, refresh]);
    const lastTripId = clientInfoData?.lastAndUpcomingTripDetails?.some((trip) => trip?.tripTime === "last_trip");
    const upcomingTripId = clientInfoData?.lastAndUpcomingTripDetails?.some((trip) => trip?.tripTime === "upcoming_trip");
    const upcomingTrip = clientInfoData?.lastAndUpcomingTripDetails?.find((trip) => trip?.tripTime === "upcoming_trip");

    const findLastTrip = filterFarthestDepartures(lastTripDetails?.responseData?.legs);
    const findUpcomingTrip = filterNearestFutureDeparture(upcomingTripDetails?.responseData?.legs);
    const upcomingTripArray = findUpcomingTrip ? [findUpcomingTrip] : [];

    const additionalDetailsMarginAndPriorityForLastTrip = useMemo(() => {
        return clientInfoForMarginAndPriorityLastTripData?.responseData?.tripLegsResult?.find((trip) => trip.dataSourceId == findLastTrip?.[0]?.id);
    }, [clientInfoForMarginAndPriorityLastTripData?.responseData, findLastTrip]);
    const additionalDetailsMarginAndPriorityForUpcomingTrip = useMemo(() => {
        return clientInfoForMarginAndPriorityUpcomingTripData?.responseData?.tripLegsResult?.find((trip) => trip.dataSourceId == upcomingTripArray?.[0]?.id);
    }, [clientInfoForMarginAndPriorityUpcomingTripData?.responseData, upcomingTripArray]);
    const findIndexOfUpcomingTrip = useMemo(() => {
        return clientInfoForMarginAndPriorityUpcomingTripData?.responseData?.tripLegsResult?.findIndex((trip) => trip.dataSourceId == upcomingTripArray?.[0]?.id);
    }, [clientInfoForMarginAndPriorityUpcomingTripData?.responseData?.tripLegsResult, upcomingTripArray]);
    // const renderLastTripRoutes = React.useMemo(() => {
    //     return (
    //         <React.Fragment key={findLastTrip?.[0]?.originAirport?.code}>
    //             {findLastTrip?.[0]?.originAirport?.code} <span className="onewayTripIcon" /> {findLastTrip?.[0]?.destinationAirport?.code}
    //         </React.Fragment>
    //     );
    // }, [findLastTrip]);

    const renderLastTripFullRoutes = React.useMemo(() => {
        const tripRouteObj = lastTripDetails?.responseData?.tripRouteObj || {};
        const { roundTrip, routes } = tripRouteObj;
        if (roundTrip) {
            return (
                <React.Fragment key="round-trip">
                    {routes[0][0]} <span className="roundTripIcon" /> {routes[0][1]}
                </React.Fragment>
            );
        } else {
            return routes?.map((route, routeIndex) => {
                const continuousRoutes = route?.map((airport, airportIndex) => (
                    <React.Fragment key={airport}>
                        {airport} {airportIndex < route.length - 1 && <span className="onewayTripIcon" />}
                    </React.Fragment>
                ));
                return (
                    <React.Fragment key={routeIndex}>
                        {continuousRoutes} {routeIndex < routes.length - 1 && <span className="divider" />}
                    </React.Fragment>
                );
            });
        }
    }, [lastTripDetails?.responseData?.tripRouteObj]);

    const renderUpcomingTripRoutes = React.useMemo(() => {
        return (
            <React.Fragment key={upcomingTripArray?.[0]?.originAirport?.code}>
                {upcomingTripArray?.[0]?.originAirport?.code} <span className="onewayTripIcon" /> {upcomingTripArray?.[0]?.destinationAirport?.code}
            </React.Fragment>
        );
    }, [upcomingTripArray]);
    const legs = lastTripDetails?.responseData?.legs;

    const operatorNames = legs?.map((leg) => leg?.aircraft?.operator?.name).filter((name) => name !== undefined);
    const aircraftNames = legs?.map((leg) => leg?.aircraft?.name).filter((name) => name !== undefined);
    return (
        <div>
            <Dialog
                autoFocus={true}
                title="CONTACT OVERVIEW"
                onClose={() => {
                    setOpenClientProfileView(false);
                }}
                className="add-contact-details-model edit-contact-modal contact-view-modal right-open-modal w-750px"
            >
                {loading ||
                loadingPostData ||
                loadingbrokerInfoPostData ||
                loadingClientInfoFromPhoenix ||
                loadingTripInfoLastTripDetails ||
                loadingClientInfoForMarginAndPriorityDetailsLastTrip ||
                loadingTripInfoUpcomingTripDetails ||
                loadingClientInfoForMarginAndPriorityDetailsUpcomingTrip ? (
                    <LoaderInner />
                ) : (
                    <>
                        <div className="d-flex align-items-center justify-content-between">
                            {data?.responseData?.contactType != "Individual" ? (
                                <h1 className="font-300 text-primary mb-0 .mx-w-500px text-capitalize"
                                role="button"
                                onClick={() => {
                                    navigateMaverick(`contact/${data?.responseData?.contactId}`) 
                                }}
                                >{data?.responseData?.businessName}</h1>
                            ) : (
                                <h1
                                    className="font-300 text-primary mb-0 .mx-w-500px text-capitalize"
                                    role="button"
                                    onClick={() => {
                                        navigateMaverick(`contact/${data?.responseData?.contactId}`)
                                    }}>
                                    {data?.responseData?.firstName} {data?.responseData?.middleName || ""} {data?.responseData?.lastName} 
                                </h1>
                            )}
                            <div
                                className="status-title-box primary"
                                role="button"
                                onClick={() => {
                                    navigateMaverick(`contact/${data?.responseData?.contactId}`)
                                }}>
                                {rednderClientStatusIcon(data?.responseData?.contactStatus)}
                                {data?.responseData?.contactStatus ? data?.responseData?.contactStatus.replace(/([A-Z])/g, " $1").trim() : ""}
                            </div>
                        </div>
                        <div className="divider-hr mt-20 mb-20"></div>
                        <div className="row mb-25">
                            <div className="col-sm-6">
                                <p className="role-view-label mb-10">Total Trips/Avg Margin (Last 12 Mos)</p>
                                <div className="d-flex flex-column text-dark-grey">
                                    <span>
                                        <strong className="font-500">Jet Card:</strong> {clientInfoData?.jetsTrip?.trips} Trips |{" "}
                                        {clientInfoData?.jetsTrip?.margin ? clientInfoData?.jetsTrip?.margin?.toFixed(2) + "%" : "0%"}
                                    </span>
                                    <span>
                                        <strong className="font-500">Charter:</strong> {clientInfoData?.charterTrip?.trips} Trips |{" "}
                                        {clientInfoData?.charterTrip?.margin ? clientInfoData?.charterTrip?.margin?.toFixed(2) + "%" : "0%"}
                                    </span>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <p className="role-view-label mb-10">Charter CVR & Margin (Last 12 Mos)</p>
                                <div className="main-progress-box">
                                    <div className="main-circle-progress">
                                        <Pie className="circle" percentage={calculatePercentage(clientInfoData?.tripsTaken, clientInfoData?.quoteRequested)} colour="#001489" />
                                        <div className="client-info">
                                            {clientInfoData?.tripsTaken}/{clientInfoData?.quoteRequested}
                                        </div>
                                    </div>
                                    <div className="progress-list">
                                        <p className="fs-15 text-dark-black-color f-lh-20 mb-0 d-flex align-items-center">
                                            <span className="ellips primary-blue me-2"></span>Trips Taken
                                        </p>
                                        <p className="fs-15 text-dark-black-color f-lh-20 mb-0 align-items-center">
                                            <span className="ellips light-blue me-2"></span>Quotes Requested
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-25">
                            <div className="col-sm-6">
                                <p className="role-view-label mb-10">Assigned Broker(s)</p>
                                <div className="d-flex flex-column text-dark-grey">
                                    <div>
                                        {checkBrokerType(additionalDetailsMarginAndPriorityForLastTrip?.brokerName)?.length
                                            ? formatBrokerTypes(checkBrokerType(additionalDetailsMarginAndPriorityForLastTrip?.brokerName))
                                            : "-"}

                                        {/* <span>{brokerData?.responseData?.broker?.brokers?.map((broker) => broker.fullName)?.join(", ") || "-"}</span> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <p className="role-view-label mb-10">Assigned Assistant(s)</p>
                                <div className="d-flex flex-column text-dark-grey">
                                    <span>{brokerData?.responseData?.assistant?.assistant?.map((ass) => ass.fullName)?.join(", ") || "-"}</span>
                                </div>
                            </div>
                        </div>
                        <div className="divider-hr mt-20 mb-20"></div>
                        {lastTripId && lastTripDetails?.responseData?.legs?.length > 0 && (
                            <PanelBar className="client-profile-accordian mb-20 bg-light-blue">
                                <PanelBarItem
                                    title={
                                        <>
                                            <td>
                                                <h2 className="fs-18 font-500 mb-0 text-dark-grey">Last Trip Details</h2>
                                                <span className="d-flex  align-items-center" onClick={(e) => e.stopPropagation()}>
                                                <span
                                                    onClick={() => {
                                                        setOpenTripProfileView(true);
                                                        setOpenTripData(lastTripDetails?.responseData);
                                                        setDataSourceIdForTrip(lastTripDetails?.responseData?.id);
                                                        setTripId(additionalDetailsMarginAndPriorityForLastTrip?.id);
                                                        setLegId(null);
                                                    }}
                                                    className="font-600 d-flex align-items-center text-dark-primary pointer-class"
                                                >
                                                    {lastTripDetails?.responseData?.restarted && (
                                                        <span className="d-inline-block mb-1">
                                                            <img src={restart} width={18} className="relative-icon-minus" />
                                                        </span>
                                                    )}{" "}
                                                    {lastTripDetails?.responseData?.tripId}
                                                </span>
                                                    {lastTripDetails?.responseData?.aircraftTypes?.map((aircraft) => (
                                                        <div className="ms-1  blue-bubble-client-profile bubble-style">{aircraft.displayName[0]}</div>
                                                    ))}
                                                    <img src={iconViewTrip} alt="View Trip Icon" height="20" className="ms-1 relative-icon-minus-3" />
                                                </span>
                                            </td>
                                        </>
                                    }
                                >
                                    <div className="divider-hr trip-collapse-hr mt-20 mb-20"></div>
                                    <div className="main-client-trip-table row mb-20">
                                        <div className="col-sm-3 d-flex flex-column">
                                            <label className="role-view-label f-lh-11 mb-1">Full Route</label> <span className="fs-14 f-lh-13 font-600 text-dark-blue">{renderLastTripFullRoutes}</span>
                                        </div>
                                        <div className="col-sm-3 d-flex flex-column">
                                            <label className="role-view-label f-lh-11 mb-1">Date </label>{" "}
                                            <span className="fs-14 f-lh-13 text-dark-blue">{lastTripDetails?.responseData?.legs?.[0]?.displayDepartDate}</span>
                                        </div>
                                        <div className="col-sm-3 d-flex flex-column">
                                            <label className="role-view-label f-lh-11 mb-1">Time </label>{" "}
                                            <span className="fs-14 f-lh-13 text-dark-blue">{convertTo24HourFormat(lastTripDetails?.responseData?.legs?.[0]?.displayDepartTime)}</span>
                                        </div>
                                        <div className="col-sm-3 d-flex flex-column text-center">
                                            <label className="role-view-label f-lh-11 mb-1">ETE </label>
                                            <span className="fs-14 f-lh-13 text-dark-blue"> {convertMinutesInHourAndMinutes(lastTripDetails?.responseData?.legs?.[0]?.durationInHrMin) || "-"}</span>
                                        </div>
                                    </div>
                                    <div className="main-client-trip-table row">
                                        <div className="col-sm-3 d-flex flex-column">
                                            <label className="role-view-label f-lh-11 mb-1">Operator </label>
                                            <span className="fs-14 f-lh-13 text-dark-blue">
                                                {operatorNames?.length > 1
                                                    ? `${operatorNames?.[0]} + ${operatorNames?.length - 1}`
                                                    : operatorNames?.length == 1
                                                    ? operatorNames?.[0]
                                                    : !operatorNames?.length
                                                    ? "-"
                                                    : "-"}
                                                {/* {lastTripDetails?.responseData?.legs?.[0]?.aircraft?.operator?.name ? lastTripDetails?.responseData?.legs?.[0]?.aircraft?.operator?.name : "-"}{" "}
                                                {operatorNames?.length > 1 ? " +" + operatorNames?.length - 1 : ""} */}
                                            </span>
                                        </div>
                                        <div className="col-sm-3 d-flex flex-column">
                                            <label className="role-view-label f-lh-11 mb-1">Aircraft </label>
                                            <span className="fs-14 f-lh-13 text-dark-blue">
                                                {" "}
                                                {aircraftNames?.length > 1
                                                    ? `${aircraftNames?.[0]} + ${aircraftNames?.length - 1}`
                                                    : aircraftNames?.length == 1
                                                    ? aircraftNames?.[0]
                                                    : !aircraftNames?.length
                                                    ? "-"
                                                    : "-"}
                                                {/* {lastTripDetails?.responseData?.legs?.[0]?.aircraft?.name ? lastTripDetails?.responseData?.legs?.[0]?.aircraft?.name : "-"}{" "}
                                                {aircraftNames?.length > 1 ? " +" + aircraftNames?.length - 1 : ""} */}
                                            </span>
                                        </div>
                                        <div className="col-sm-3 d-flex flex-column">
                                            <label className="role-view-label f-lh-11 mb-1">Margin </label>{" "}
                                            <span className="fs-14 f-lh-13 text-dark-blue">
                                                {!afterAcceptedStatusShow(lastTripDetails?.responseData?.tripType?.id, lastTripDetails?.responseData?.status?.displayName) && (
                                                    <>
                                                        {(clientInfoForMarginAndPriorityLastTripData?.responseData?.margin?.toFixed(2) ||
                                                            clientInfoForMarginAndPriorityUpcomingTripData?.responseData?.margin?.toFixed(2)) + "%" || "0%"}
                                                    </>
                                                )}
                                            </span>
                                        </div>
                                        <div className="col-sm-3 d-flex flex-column text-center">
                                            <label className="role-view-label f-lh-11 mb-1">PAX</label>
                                            <span className="fs-14 f-lh-13 text-dark-blue"> {lastTripDetails?.responseData?.legs?.[0]?.numberOfPassengers || "-"}</span>
                                        </div>
                                    </div>
                                </PanelBarItem>
                            </PanelBar>
                        )}
                        {upcomingTripId && upcomingTripArray?.length > 0 && (
                            <PanelBar className="client-profile-accordian">
                                <PanelBarItem
                                    title={
                                        <>
                                            <td>
                                                <h2 className="fs-18 font-500 mb-0 text-dark-grey">Upcoming Trip Details</h2>
                                                <span
                                                    onClick={() => {
                                                        navigate(`/scenario-builder/${upcomingTrip?.id}`);
                                                        setOpenClientProfileView(false);
                                                    }}
                                                    className="font-600 d-flex align-items-center  text-dark-primary pointer-class"
                                                >
                                                    Schedule
                                                    <img src={startShedulerBlue} alt="Sheduler Icon" height="20" className="ms-1" />
                                                </span>
                                            </td>
                                        </>
                                    }
                                >
                                    <div className="divider-hr trip-collapse-hr mt-20 mb-20"></div>
                                    <div className="main-client-trip-table row mb-20">
                                        <div className="col-sm-3 d-flex flex-column">
                                            <label className="role-view-label f-lh-11 mb-1">Leg</label>{" "}
                                            <span className="fs-14 f-lh-13 text-dark-blue">
                                                Leg {findIndexOfUpcomingTrip + 1} of {upcomingTripDetails?.responseData?.legs?.length}
                                            </span>
                                        </div>
                                        <div className="col-sm-3 d-flex flex-column">
                                            <label className="role-view-label f-lh-11 mb-1">Route </label> <span className="fs-14 f-lh-13 font-600 text-dark-blue">{renderUpcomingTripRoutes}</span>
                                        </div>
                                        <div className="col-sm-3 d-flex flex-column">
                                            <label className="role-view-label f-lh-11 mb-1">Trip ID </label>{" "}
                                            <span
                                                onClick={() => {
                                                    setOpenTripProfileView(true);
                                                    setOpenTripData(upcomingTripDetails?.responseData);
                                                    setDataSourceIdForTrip(upcomingTripDetails?.responseData?.id);
                                                    setTripId(additionalDetailsMarginAndPriorityForUpcomingTrip?.id);
                                                    setLegId(null);
                                                }}
                                                className="fs-14 f-lh-13 font-600 text-dark-primary pointer-class"
                                            >
                                                {upcomingTripDetails?.responseData?.tripId}
                                                {upcomingTripDetails?.responseData?.restarted && (
                                                    <span>
                                                        <img src={restart} width={18} className="relative-icon-minus-2" />
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                        <div className="col-sm-3 d-flex flex-column">
                                            <label className="role-view-label f-lh-11 mb-1">Status </label>
                                            <div className="client-trip-status d-inline-flex">
                                                {rednderTripStatusIcon(upcomingTripDetails?.responseData?.status?.displayName?.trim())}
                                                <span className="fs-14 f-lh-13 text-dark-blue">{upcomingTripDetails?.responseData?.status?.displayName}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="main-client-trip-table row">
                                        <div className="col-sm-3 d-flex flex-column">
                                            <label className="role-view-label f-lh-11 mb-1">Date/Time </label>
                                            <span className="fs-14 f-lh-13 text-dark-blue">
                                                {upcomingTripArray?.[0]?.displayDepartDate}/{convertTo24HourFormat(upcomingTripArray?.[0]?.displayDepartTime)}
                                            </span>
                                        </div>
                                        <div className="col-sm-3 d-flex flex-column">
                                            <label className="role-view-label f-lh-11 mb-1">ETE </label>
                                            <span className="fs-14 f-lh-13 text-dark-blue">{convertMinutesInHourAndMinutes(upcomingTripArray?.[0]?.durationInHrMin)}</span>
                                        </div>
                                        <div className="col-sm-3 d-flex flex-column">
                                            <label className="role-view-label f-lh-11 mb-1">PAX</label>{" "}
                                            <span className="fs-14 f-lh-13 text-dark-blue">{upcomingTripArray?.[0]?.numberOfPassengers || "-"}</span>
                                        </div>
                                        <div className="col-sm-3 d-flex flex-column">
                                            <label className="role-view-label f-lh-11 mb-1">Priority</label>
                                            <span className="fs-14 f-lh-13 text-dark-blue">
                                                <td className="text-center cursor-pointer">
                                                    <OverlayTrigger
                                                        rootClose={true}
                                                        trigger="click"
                                                        placement="top"
                                                        overlay={
                                                            <Popover id="popover-basic" className="custom-popover">
                                                                {/* <Popover.Header as="h3" className="bg-light-blue border-0 mb-12">
                                                                    <p className="fs-12 font-400 mb-1 text-light-grey">Requested Details</p>
                                                                    {props.dataItem?.phoLegsCreatedDate ? (
                                                                        <div className="fs-14">
                                                                            {convertToLocalTime(props.dataItem?.phoLegsCreatedDate)} ({formatRelativeTime(props.dataItem?.phoLegsCreatedDate)}) by{" "}
                                                                            {props.dataItem.clientName}
                                                                        </div>
                                                                    ) : (
                                                                        <div className="fs-14">by {props.dataItem.clientName}</div>
                                                                    )}{" "}
                                                                    {props.dataItem.id}
                                                                </Popover.Header> */}
                                                                <Popover.Body>
                                                                    <CustomSingleSelect
                                                                        dataList={getAllPriorityMasterData?.data?.responseData || []}
                                                                        value={{}}
                                                                        handleFilterName={(e) => {
                                                                            if (
                                                                                e.nativeEvent.code === "ArrowDown" ||
                                                                                e.nativeEvent.code === "ArrowUp" ||
                                                                                e.nativeEvent.code === "Enter" ||
                                                                                e.nativeEvent.code === "ArrowLeft" ||
                                                                                e.nativeEvent.code === "NumpadEnter" ||
                                                                                e.nativeEvent.code === "End" ||
                                                                                e.nativeEvent.code === "Home" ||
                                                                                e.nativeEvent.code === "ArrowRight"
                                                                            )
                                                                                {return;}
                                                                            handleLegListAssignPriority("priority", e.target.value, additionalDetailsMarginAndPriorityForUpcomingTrip?.legId);
                                                                        }}
                                                                        keyName="priority"
                                                                        name="priority"
                                                                        textField="priority"
                                                                        dataItemKey="id"
                                                                        label={
                                                                            additionalDetailsMarginAndPriorityForUpcomingTrip?.priority ? (
                                                                                <>
                                                                                    <p className="fs-12 mb-0 text-light-grey text-start"> Priority</p>{" "}
                                                                                    <img
                                                                                        className="me-2 align-text-top"
                                                                                        src={
                                                                                            additionalDetailsMarginAndPriorityForUpcomingTrip?.priority == "1"
                                                                                                ? priorityGreenBar
                                                                                                : additionalDetailsMarginAndPriorityForUpcomingTrip?.priority == "2"
                                                                                                ? priorityOrangeBar
                                                                                                : additionalDetailsMarginAndPriorityForUpcomingTrip?.priority == "3"
                                                                                                ? priorityRedBar
                                                                                                : priorityOrangeBar
                                                                                        }
                                                                                        alt="Green bar"
                                                                                    />
                                                                                    <p className="fs-16 mb-0 text-dark-grey d-inline-block">
                                                                                        {" "}
                                                                                        {additionalDetailsMarginAndPriorityForUpcomingTrip?.priority == "1"
                                                                                            ? "Low"
                                                                                            : additionalDetailsMarginAndPriorityForUpcomingTrip?.priority == "2"
                                                                                            ? "Medium"
                                                                                            : additionalDetailsMarginAndPriorityForUpcomingTrip?.priority == "3"
                                                                                            ? "High"
                                                                                            : "Medium"}
                                                                                    </p>
                                                                                </>
                                                                            ) : (
                                                                                "Priority"
                                                                            )
                                                                        }
                                                                    />
                                                                </Popover.Body>
                                                            </Popover>
                                                        }
                                                    >
                                                        <p className="mb-0">
                                                            <img
                                                                className="me-1 relative-icon-minus-3 align-bottom"
                                                                src={
                                                                    additionalDetailsMarginAndPriorityForUpcomingTrip?.priority == "1"
                                                                        ? priorityGreenBar
                                                                        : additionalDetailsMarginAndPriorityForUpcomingTrip?.priority == "2"
                                                                        ? priorityOrangeBar
                                                                        : additionalDetailsMarginAndPriorityForUpcomingTrip?.priority == "3"
                                                                        ? priorityRedBar
                                                                        : priorityOrangeBar
                                                                }
                                                                alt="Green bar"
                                                            />
                                                            {additionalDetailsMarginAndPriorityForUpcomingTrip?.priority == "1"
                                                                ? "Low"
                                                                : additionalDetailsMarginAndPriorityForUpcomingTrip?.priority == "2"
                                                                ? "Medium"
                                                                : additionalDetailsMarginAndPriorityForUpcomingTrip?.priority == "3"
                                                                ? "High"
                                                                : "Medium"}
                                                        </p>
                                                    </OverlayTrigger>
                                                </td>
                                            </span>
                                        </div>
                                    </div>
                                </PanelBarItem>
                            </PanelBar>
                        )}
                        <div className="model-footer-action">
                            <Button className="height-42 mob-width-full btn-large-padding border-0 fs-14 font-600" themeColor="primary" onClick={() => setOpenClientProfileView(false)}>
                                Close Window
                            </Button>
                        </div>
                    </>
                )}
            </Dialog>
        </div>
    );
};

export default ClientProfile;
function filterFarthestDepartures(legs) {
    if (!Array.isArray(legs) || legs.length === 0) {
        return [];
    }

    const legsWithDateObjects = legs?.map((leg) => {
        const [month, day, year] = leg?.departDate?.split("-").map(Number);
        const [hours, minutes, seconds] = leg?.departTime?.split(":").map(Number);
        const departDateTime = new Date(year, month - 1, day, hours, minutes, seconds);
        return { ...leg, departDateTime };
    });

    const maxDepartDateTime = legsWithDateObjects?.reduce((maxDate, leg) => {
        return leg?.departDateTime > maxDate ? leg.departDateTime : maxDate;
    }, legsWithDateObjects[0].departDateTime);

    const farthestLegs = legsWithDateObjects?.filter((leg) => {
        return leg.departDateTime?.getTime() === maxDepartDateTime?.getTime();
    });

    return farthestLegs;
}
function filterNearestFutureDeparture(legs) {
    if (!Array.isArray(legs) || legs.length === 0) {
        return null; // Return null for no legs
    }

    const now = new Date(); // Get the current date and time

    const legsWithDateObjects = legs?.map((leg) => {
        const [month, day, year] = leg?.departDate?.split("-").map(Number);
        const [hours, minutes, seconds] = leg?.departTime?.split(":").map(Number);
        const departDateTime = new Date(year, month - 1, day, hours, minutes, seconds);
        return { ...leg, departDateTime };
    });

    // Filter legs that have a departure time in the future
    const futureLegs = legsWithDateObjects?.filter((leg) => {
        return leg.departDateTime > now;
    });

    if (futureLegs.length === 0) {
        return null; // Return null if there are no future legs
    }

    // Find the leg with the nearest future departure time
    const nearestFutureLeg = futureLegs.reduce((nearestLeg, leg) => {
        return leg.departDateTime < nearestLeg.departDateTime ? leg : nearestLeg;
    }, futureLegs[0]);

    return nearestFutureLeg;
}
