import { Loader as KLoader } from "@progress/kendo-react-indicators";

export default function LoaderInner() {
    return (
        <div className="bg-preloader-inner" style={{ zIndex: 9998996969 }}>
            <div className="loader-container" data-testid="loader-container">
                <KLoader type="infinite-spinner" size="large" />
            </div>
        </div>
    );
}
